<template>
  <div class="tabvertical">
    <c-tab
      type="vertical"
      :tabItems.sync="tabItems"
      :height.sync="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :height="tabHeight"
          :workPermit.sync="workPermit"
          :supWorks.sync="supWorks"
          :saveCall.sync="saveCall"
          :isWriting="isWriting"
        />
      </template>
    </c-tab>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'swp-references',
  props: {
    workPermit: {
      type: Object,
      default: function() {
        return {
          sopWorkPermitId: '',  // 작업허가서 일련번호
          plantCd: '',  // 사업장 코드
          sopName: '',  // 작업명
          mdmSopId: '',  // 안전작업 표준 일련번호_SOP에서 불러와서 관련 정보 표시후 수정
          permitNo: '',  // 허가번호_(YYYYMMDD-01)
          swpStepCd: '',  // 진행단계 코드
          applicationDeptCd: '',  // 신청인 부서코드
          applicationDeptName: '',
          applicationUserId: '',  // 신청인 ID
          applicationUserName: '',
          permitTypeCd: '',  // 허가서 구분_화기/일반
          permitDate: '',  // 허가일
          workStartTime: '', // 작업시작시간
          workEndTime: '', // 작업종료시간
          workTime: [], // 작업시간
          processCd: '',  // 작업공정
          workLocation: '',  // 작업장소
          equipmentCd: '',  // 설비번호_설비마스터
          workSummary: '',  // 작업개요
          specialRequirements: '',  // 특별요구사항
          maintenanceDeptCheckUserId: '',  // 정비부서 책임자 - 안전조치확인
          maintenanceDeptEntryUserId: '',  // 정비부서 입회자 - 안전조치확인
          workCompleteTime: '',  // 작업완료시간
          workCompleteEntryUserId: '',  // 작업완료 입회자/확인자
          workCompleteWorkUserId: '',  // 작업완료 작업자
          issuedDeptCd: '',  // 발급자 부서코드
          issuedUserId: '',  // 발급자 ID
          approvalDeptCd: '',  // 승인자 부서코드
          approvalUserId: '',  // 승인자 ID
          relationCooperation1DeptCd: '',  // 관련부서 협조자1 부서코드
          relationCooperation1UserId: '',  // 관련부서 협조자1 ID
          relationCooperation2DeptCd: '',  // 관련부서 협조자2 부서코드
          relationCooperation2UserId: '',  // 관련부서 협조자2 ID
          vendorCd: '',  // 작업업체 코드
          overWorkFlag: '',  // 연장작업 여부
          psmFlag: 'N',  // PSM 관련 여부
          sopMocId: '',  // MOC 일련번호
          assessWriteUserId: '',  // 작업위험성평가_작성자 ID
          assessApprovalUserId: '',  // 작업위험성평가_승인자 ID
          sopAssessmentCheckTypeCd1: '',  // 잠재위험요소1
          sopAssessmentCheckTypeCd2: '',  // 잠재위험요소2
          sopAssessmentCheckTypeCd3: '',  // 잠재위험요소3
          sopAssessmentCheckTypeCd4: '',  // 잠재위험요소4
          sopAssessmentCheckTypeCd5: '',  // 잠재위험요소5
          sopAssessmentCheckTypeCd6: '',  // 잠재위험요소6
          regUserId: '',  // 등록자 ID
          chgUserId: '',  // 수정자 ID
          supWorks: [],
          deleteSupWorks: [],
          checkResults: [],
          gases: [],
          gasChecks: [], // 저장용
          gasCheckVals: [], // 저장용
          deleteGasChecks: [],
          deleteGasCheckVals: [],
          maps: [],
          deleteMaps: [],
          workers: [],
          deleteWorkers: [],
          assessments: [],
          deleteAssessments: [],
          overs: [],
          deleteOvers: [],
          protectiveGears: [],
          deleteProtectiveGears: [],
          lotos: [],
          deleteLotos: [],
        }
      }
    },
    supWorks: {
      type: Array,
      default: function() {
        return [];
      },
    },
    isWriting: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
    saveCall: {
      type: Object,
      default: function() {
        return {
          saveCallData: '',
        }
      }
    },
  },
  data() {
    return {
      tabHeight: '500px',
      splitter: 5,
      tab: 'resources',
      tabItems: [
        { name: 'resources', icon: 'map', label: '작업위치<br />(지도)', component: () => import(`${'./swpResources.vue'}`) },
        { name: 'contractor', icon: 'engineering', label: '작업자', component: () => import(`${'./swpContractor.vue'}`) },
        { name: 'asssessment', icon: 'verified', label: '작업<br />위험성평가', component: () => import(`${'./swpAssessment.vue'}`) },
        { name: 'loto', icon: 'lock', label: 'LOTO', component: () => import(`${'./swpLoto.vue'}`) },
        { name: 'attach', icon: 'attach_file', label: '첨부파일', component: () => import(`${'./swpAttach.vue'}`) },
      ],
      editable: true,
      detailUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    height() {
      this.setTabHeight();
    }
  },
  methods: {
    init() {
      this.setTabHeight();
    },
    setTabHeight() {
      let returnText = '500px';
      returnText = this.height && !isNaN(Number(this.height.replace('px', ''))) ? String(Number(this.height.replace('px', '')) - 30) + 'px' : returnText;
      this.tabHeight = returnText;
    },
  }
};
</script>
